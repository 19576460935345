<template>
  <v-form @submit.prevent="submit" class="mb-3 h-100">
    <list title="FILTERS" :filter="true" class="h-100">
      <hr class="mt-0" />
      <v-row>
        <v-col cols="12" class="pt-4">
          <div class="h-35">
            <v-text-field
              label="search in title"
              color="SonicSilver"
              v-model="filter.title.val"
              outlined
              dense
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="h-35">
            <v-text-field
              label="sender name"
              class="no-error-msg mt-0 pt-0"
              color="SonicSilver"
              v-model="filter.name.val"
              outlined
              dense
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="h-35">
            <v-text-field
              label="sender email address"
              class="pt-0"
              color="SonicSilver"
              v-model="filter.email.val"
              outlined
              dense
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="h-35">
            <date-input
              label="date, after"
              color="SonicSilver"
              v-model="filter.created_at.val1"
              outlined
              dense
            ></date-input>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="h-35">
            <date-input
              label="date, before"
              color="SonicSilver"
              v-model="filter.created_at.val2"
              outlined
              dense
            ></date-input>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col cols="4" class="pr-0">
          <block-button
            height="30"
            class="w-100 cancel__button br__mode btn__size__14"
            text="CLEAR"
            color="black"
            @click.native="clearFilter"
          ></block-button>
        </v-col>
        <v-col cols="8 pl-1">
          <block-button
            height="30"
           class="w-100 site__button width-auto add__mode btn__size__18"
            text="EXECUTE"
            type="submit"
          ></block-button>
        </v-col>
      </v-row>
    </list>
  </v-form>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import { cloneDeep } from "lodash";
const defaultFilter = {
  created_at: {
    type: "between",
    val1: null,
    val2: null,
    name : "created at"
  },
  name: {
    type: "like",
    val: null,
    name : "sender name"
  },
  title: {
    type: "like",
    val: null,
    name : "title"
  },
  email: {
    type: "like",
    val: null,
    name : "email"
  },
};
export default {
  data() {
    return {
      filter: {},
    };
  },
  computed:{
    ...mapGetters('contact', ["getFiltersContact"]),
  },
  methods: {
    ...mapMutations("contact", ["SET_FILTER_CONTACT_MESSAGE","SET_IS_FILTERED_CONTACT"]),
    ...mapActions("contact", ["loadContactMessages"]),
    submit() {
      this.SET_IS_FILTERED_CONTACT(true)
      this.SET_FILTER_CONTACT_MESSAGE(this.filter);
      this.loadContactMessages();
    },
    clearFilter() {
      this.SET_IS_FILTERED_CONTACT(false)
      this.filter = cloneDeep(defaultFilter);
      this.SET_FILTER_CONTACT_MESSAGE(cloneDeep(this.filter));
      this.loadContactMessages();
    },    
    loadPastFilter(){
      this.filter = cloneDeep({...defaultFilter , ...this.getFiltersContact})
    }
  },
  created() {
    this.loadPastFilter()
  }
};
</script>
<style scoped>
.h-35 {
  height: 35px !important;
}
</style>
