<template >
  <div class="wa__container">
    <header class="pt-3">
      <div class="pt-3">
        <v-row>
          <v-col cols="12">
            <site-header
              backRouteName="dashboard"
              backText="home"
              title="contact us forms"
              sub-title="those who contacted us"
            ></site-header>
          </v-col>
        </v-row>
      </div>
    </header>
    <main>
      <div>
        <v-row class="justify-content-center pb-4 wa__models">
          <v-col cols="12" md="2">
            <ContactUsFilter />
          </v-col>
          <v-col cols="12" md="10">
            <ContactUsItems />
          </v-col>
        </v-row>
      </div>
    </main>
    <footer>
      <div class="wa__home--footer">
        <site-footer text="All Right Reserved"></site-footer>
      </div>
    </footer>
    <div class="text-center">
      <MessageReplyModal v-if="isModal('modal_messageReplay')"/>
      <emailConfirmationModal v-if="isModal('modal_emailConfirmation')" :filters="getFiltersContact" />
      <MessageDetailsModal />
    </div>
  </div>
</template>
<script>
import ContactUsItems from "../components/ContactUs/Items";
import ContactUsFilter from "../components/ContactUs/Filter";
import MessageReplyModal from "../components/ContactUs/MessageReplyModal";
import MessageDetailsModal from "../components/ContactUs/MessageDetailsModal";
import emailConfirmationModal from "@/components/Global/Modal/EmailConfirmation.vue"
import {mapGetters,mapMutations} from 'vuex';
export default {
  components: {
    ContactUsFilter,
    ContactUsItems,
    MessageReplyModal,
    MessageDetailsModal,
    emailConfirmationModal
  },
  computed: {
    ...mapGetters("modal", ["isModal"]),
    ...mapGetters("contact", ["getFiltersContact"]),
  },
  methods: {
    ...mapMutations('contact', ['DESTROY_HISTORY']),
  },
  destroyed(){
    this.DESTROY_HISTORY()
  }
};
</script> 