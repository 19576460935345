<template>
  <basic-modal @open="openModal" width="1250" transition="slide-x-transition">
    <template #header>
      <divider title="message details" sub-title="let’s dig in" size="small"></divider>
    </template>
    <template #default>
      <div class="mt-4 px-4">
        <SectionTitle title="sender info" />
        <v-row class="mt-2">
          <v-col cols="3">
            <request-details-information title="NAME" :subTitle="messageInfo.name" />
          </v-col>
          <v-col cols="3">
            <request-details-information
              title="title"
              :subTitle="`${messageInfo.title}`"
            />
          </v-col>
          <v-col cols="4">
            <request-details-information
              title="email address"
              :subTitle="messageInfo.email"
            />
          </v-col>
          <v-col cols="3">
            <request-details-information
              title="cell phone"
              :subTitle="messageInfo.cell_phone"
            />
          </v-col>
          <v-col cols="2">
            <request-details-information
              title="created at"
              :subTitle="`${messageInfo.created_at}`"
            />
          </v-col>
        </v-row>
        <v-row class="mt-2">
          <v-col cols="3">
            <request-details-information
              title="company name"
              :subTitle="`${messageInfo.company_name}`"
            />
          </v-col>
        </v-row>
        <SectionTitle title="message" class="mt-7" />
        <v-row class="mb-16">
          <v-col cols="12">
            <request-details-information
              title="messsage text"
              :subTitle="messageInfo.message"
            />
          </v-col>
          <v-col cols="12" v-if="messageInfo.seen_at">
            <request-details-information
              title="seen at"
              :subTitle="messageInfo.seen_at"
            />
          </v-col>
        </v-row>
      </div>
    </template>
    <template #footer>
      <v-spacer></v-spacer>
      <div class="px-4 py-2 d-flex justify-space-between align-items-center w-100">
        <block-button
          class="btn__modal--cancel btn__size__18"
          text="cancel"
          icon="WMi-cancel-linear"
          text-mode="text"
          @click.native="$_closeModal()"
        ></block-button>
        <block-button
          height="24"
          class="btn__modal--assign ml-5 site__button width-auto btn__size__16"
          icon="WMi-right-open"
          text="send the reply"
          @click.native.stop="sendReplay"
        ></block-button>
      </div>
    </template>
  </basic-modal>
</template>
<script>
import SectionTitle from "../Global/Section/SectionTitle.vue";
import RequestDetailsInformation from "../Global/Section/RequestDetailsInformation.vue";
export default {
  name: "modal_messageDetails",
  components: { SectionTitle, RequestDetailsInformation },
  data: () => ({
    messageInfo: {},
  }),
  methods: {
    openModal({ model }) {
      this.messageInfo = model;
    },
    sendReplay(){
      this.$_closeModal();
      this.$_openModal('messageReplay',{model:this.messageInfo})
    }
  },
};
</script>
<style scoped>
.v-input {
  padding-top: 0;
}
.btn__modal--assign {
  padding: 20px 35px !important;
  font-family: "Montserrat-ExtraLight";
  letter-spacing: 2px;
}
</style>
