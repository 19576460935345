<template>
  <list title="message" class="wa__large">
    <hr class="mt-0" />
    <template>
      <v-row class="pr-7 pb-1 d-none d-md-flex">
        <v-col cols="5" class="wa__message__title--title"
          ><span class="pl-4">title</span></v-col
        >
        <v-col cols="4" class="wa__message__title--title pl-7"
          ><span>sender</span></v-col
        >
        <v-col cols="3" class="wa__message__title--title text-right pr-5"
          ><span class="pl-14">controls</span></v-col
        >
      </v-row>
      <data-iterator :items="getContactMessages" :is-filtered="isFilteredContact" @pagination="changePagination" :pagination="getPaginationContactMessage" :loading="getContactLoading" class="row mt-0 px-3">
            <template v-slot:loading>
                <loading />
            </template>
            <template #no-items>
              <!-- fix component -->
                <!-- <no-items :src="$_staticPath('images/global/animated/product.gif')" route-name="AddProduct" textFa=" هنوز هیچ محصولی ثبت نکرده اید! " textEn=" No Products Yet! " themeColor="product" btnText="ثبت اولین  محصول" btnIcon="plus" /> -->
                <no-items />
            </template>
            <template #no-results>
              <!-- fix component -->
                <!-- <no-items :src="$_staticPath('images/global/animated/no-result.gif')" textFa=" متاسفانه محصولی مطابق با فیلتر های شما پیدا نکردیم! " textEn=" Sorry, No Product! " themeColor="product" btnText=" حذف فیلتر ها " /> -->
                <no-items />
            </template>

            <template v-slot:items="{item}">
                <messageItem :key="item.id" :message="item" />
            </template>
        </data-iterator>
    </template>
    <!-- <div class="text-center">
      <project-assign v-if="isModal('modal_assignProject')" />
    </div> -->
  </list>
</template>
<script>
import messageItem from "./Item";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  components: {
    messageItem,
  },
  data: () => ({}),
  computed: {
    ...mapGetters("contact", [
      "getContactMessages",
      "getPaginationContactMessage",
      "getContactLoading",
      "isFilteredContact",
    ]),
  },
  methods: {
    ...mapActions("contact", ["loadContactMessages"]),
    ...mapMutations("contact", ["SET_PAGINATION"]),
    changePagination(pagination) {
      if (this.getContactMessages.page !== pagination.page) {
        this.SET_PAGINATION({ pagination});
        this.loadContactMessages();
      }
    },
  },
  created() {
    this.loadContactMessages();
  },
};
</script>
<style scoped>
.wa__message__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  height: 16px;
}
.wa__message__title--title {
  font-family: "Montserrat";
  font-size: 0.6rem;
  color: var(--color-light-gray);
  text-transform: uppercase;
  letter-spacing: 1px;
}
</style>
