<template>
  <div
    class="wa__message mb-2"
    @click="$_openModal('messageDetails', { model: message })"
  >
    <v-row class="text-left">
      <v-col cols="5" class="wa__message__name">
        <div class="d-flex align-items-center h-100 message">
          <div class="ml-4 message-box">
            <div class="message__title mt-1">{{ message.name }}</div>
            <v-chip
              label
              color="grey lighten-3"
              text-color="grey darken-1"
              x-small
              class="text-uppercase"
              >sent on {{ message.date }} - {{ message.time }}</v-chip
            >
          </div>
        </div>
      </v-col>
      <v-col cols="4">
        <div class="message__sender">
          <span>Sent BY:</span>
          <div>
            <Name :title="message.name" :sub-title="message.email" />
          </div>
        </div>
      </v-col>
      <v-col cols="3">
        <div class="d-flex justify-content-end align-center h-100">
          <circle-button
            icon=" WMi-back"
            color="black"
            @click.native.stop="
              $_openModal('messageReplay', { model: message })
            "
          ></circle-button>
          <circle-button
            icon="WMi-trash"
            color="red"
            bg-color="bgLightPink"
            @click.native.stop="() => {}"
          ></circle-button>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Name from "../Global/Section/Name.vue";
export default {
  components: {
    Name,
  },
  props: {
    message: {
      type: Object,
      default: null,
    },
  },
};
</script>
<style scoped>
.message-box {
  line-height: 20px;
}
.message__title {
  font-size: 20px;
}
.message__sender {
  line-height: 16px;
}
.message__sender span {
  font-size: 10px;
  color: #c3c3c3;
}
.wa__message {
  border: 1px solid var(--color-light-gray);
  border-radius: 4px;
  width: 100%;
  font-family: "Montserrat-regular", sans-serif;
  padding: 5px 10px;
}

/* message item */
.wa__message__name {
  display: flex;
  align-items: flex-end;
  margin-top: -5px;
  cursor: pointer;
}

.wa__message__name--image {
  height: 52px;
}

.wa__message__name--image figure img {
  height: 55px;
  width: 62px;
  border-radius: 4px;
}

.wa__message__name a {
  text-decoration: none;
  display: block;
  color: initial;
}
.wa__message__status--hired {
  color: var(--color-light-gray);
  font-size: 8px;
  margin-top: -2px;
}

.wa__message__status--title {
  font-size: 11px;
  text-transform: uppercase;
}

.btn-operations {
  font-size: 6px;
  position: relative;
  top: -0.6rem;
  cursor: default !important;
  padding: 4px 5px !important;
  border-radius: 2px !important;
  letter-spacing: 1px !important;
}

.wa__message__jobs--jobs {
  margin-top: -5px;
}

.wa__message__jobs--button {
  display: table-caption;
}

.wa__message__control {
  margin-top: -7px !important;
}
/* button */

.wa__message__control a {
  text-decoration: none;
  display: block;
  margin-right: 7px;
}

.btn-join {
  font-family: "Montserrat-regular";
  color: var(--color-gray);
  font-size: 11px;
  letter-spacing: 0px !important;
  border-radius: 1px !important;
  background-color: var(--color-bg-gray) !important;
  cursor: default !important;
}

.btn-jobs {
  font-family: "Montserrat-bold";
  font-size: 13px;
  font-weight: 900;
  padding: 1px 9px !important;
  position: relative;
  top: -0.5rem;
  border-radius: 1px !important;
  letter-spacing: 2px;
}
/*If it were not for btn-joined*/
.btn-jobs--only {
  top: 0.6rem !important;
}
.btn-available {
  font-family: "Montserrat-regular";
  padding: 13px !important;
  margin-top: 7px;
  letter-spacing: 4px;
  font-size: 15px;
}
</style>
