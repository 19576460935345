<template>
  <basic-modal @open="setData" width="800" transition="slide-x-transition">
    <template #header>
      <divider title="message reply" sub-title="let’s respond" size="small"></divider>
    </template>
    <template #default>
      <div class="mt-4 px-9">
        <v-autocomplete
          label="email template"
          color="SonicSilver"
          class="mt-0 pt-0"
          :items="[...getUserTemplates, ...{ id: -1, title: 'no template' }]"
          item-value="id"
          item-text="title"
          outlined
          dense
          return-object
          v-model="form.email_template"
        ></v-autocomplete>
        <v-text-field
          label="title"
          v-model="subject"
          color="SonicSilver"
          dense
          outlined
        ></v-text-field>
        <v-textarea
          label="reply text"
          v-model="message"
          color="SonicSilver"
          rows="10"
          dense
          outlined
        ></v-textarea>
      </div>
    </template>
    <template #footer>
      <v-spacer></v-spacer>
      <div class="px-3 py-2 d-flex justify-space-between align-items-center w-100">
        <block-button
          class="btn__modal--cancel px-0 btn__size__18"
          text="cancel"
          icon="WMi-cancel-linear"
          text-mode="text"
          @click.native="$_closeModal()"
        ></block-button>
        <block-button
          height="24"
          class="btn__modal--assign ml-5 site__button width-auto btn__size__16"
          icon="WMi-right-open"
          :loading="requestLoading"
          @click.native="send()"
          text="send"
        ></block-button>
      </div>
    </template>
  </basic-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ContactRepository from "@/abstraction/repository/contactRepository";
const contactRepository = new ContactRepository();
export default {
  name: "modal_messageReplay",
  data() {
    return {
      requestLoading: false,
      form: {
        email_template: { id: -1, title: "no template" },
      },
    };
  },
  computed: {
    ...mapGetters("template", ["getUserTemplates"]),
    subject: {
      get() {
        if (this.form.email_template.id === -1) {
          return this.form.subject;
        } else {
          return this.form.email_template.title;
        }
      },
      set(value) {
        if (this.form.email_template.id === -1) {
          this.form.subject = value;
        } else {
          this.form.subject = null;
        }
      },
    },
    message: {
      get() {
        if (this.form.email_template.id === -1) {
          return this.form.message;
        } else {
          return this.form.email_template.message;
        }
      },
      set(value) {
        if (this.form.email_template.id === -1) {
          this.form.message = value;
        } else {
          this.form.message = null;
        }
      },
    },
  },
  methods: {
    ...mapActions("template", ["loadTemplates"]),
    async send() {
      try {
        this.requestLoading = true;
        const response = await contactRepository.replyEmail(this.form);
        this.$_closeModal();
        this.$_openModal("emailConfirmation", {
          model: response,
        });
      } catch (e) {
        return e;
      } finally {
        this.requestLoading = false;
      }
    },
    setData({ model }) {
      if (model) {
        this.form.contactId = model.id;
        this.form.subject = `RE:[${model.title}]`
      }
    },
  },
  created() {
    this.loadTemplates();
  },
};
</script>

<style scoped>
.v-input {
  padding-top: 0;
}

.btn__modal--assign {
  padding: 20px 35px !important;
  font-family: "Montserrat-ExtraLight";
  letter-spacing: 2px;
}
</style>
